var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "text-left" }, [
            _c("span", { staticClass: "f16 font-weight-light" }, [
              _vm._v(
                _vm._s(_vm._f("moment")(_vm.podcast.createDate, "from", "now"))
              ),
            ]),
          ]),
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                xs: "12",
                sm: "12",
                md: "8",
                lg: "6",
                xl: "4",
                cols: "12",
              },
            },
            [
              _c("v-img", {
                staticClass: "rounded-lg",
                attrs: {
                  "lazy-src": require("@/assets/images/placeholder.png"),
                  src: _vm.podcast.picture,
                  aspect: "1/1",
                },
              }),
              _c("vue-plyr", [
                _c("audio", { attrs: { controls: "", playsinline: "" } }, [
                  _c("source", {
                    attrs: { src: _vm.podcast.url, type: "audio/mp3" },
                  }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "mt-3" },
            [
              _c("span", { staticClass: "f16 font-weight-bold" }, [
                _vm._v(_vm._s(_vm.podcast.title)),
              ]),
              _c("p", {
                staticClass: "f14",
                domProps: { innerHTML: _vm._s(_vm.podcast.description) },
              }),
              _c(
                "v-chip-group",
                {
                  attrs: {
                    mandatory: "",
                    "show-arrows": "",
                    "active-class": "primary--text",
                  },
                },
                _vm._l(_vm.podcast.groups, function (cat) {
                  return _c("v-chip", { key: cat.id, attrs: { small: "" } }, [
                    _vm._v(" " + _vm._s(cat.value) + " "),
                  ])
                }),
                1
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("span", { staticClass: "f16 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("Submit comment"))),
                  ]),
                  _c("v-textarea", {
                    staticClass: "mt-3 f14 rounded",
                    attrs: {
                      flat: "",
                      outlined: "",
                      "auto-grow": "",
                      label: _vm.$t("Please write your comment"),
                      counter: "500",
                      rules: _vm.commentRules,
                    },
                    model: {
                      value: _vm.commentValue,
                      callback: function ($$v) {
                        _vm.commentValue = $$v
                      },
                      expression: "commentValue",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-0 mb-3 rounded",
                      attrs: {
                        elevation: "0",
                        block: "",
                        color: "info",
                        disabled: !_vm.valid,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.validateComment()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("Submit")))]
                  ),
                ],
                1
              ),
              _vm.comments != null && _vm.comments.length == 1
                ? _c(
                    "span",
                    { staticClass: "f16 font-weight-bold d-block mt-6" },
                    [_vm._v(_vm._s(_vm.$t("comment")))]
                  )
                : _vm.comments != null && _vm.comments.length > 1
                ? _c(
                    "span",
                    { staticClass: "f16 font-weight-bold d-block mt-6" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.comments.length) +
                          " " +
                          _vm._s(_vm.$t("comments")) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.comments, function (item) {
                return _c(
                  "v-card",
                  {
                    key: item.id,
                    staticClass: "border my-3",
                    attrs: { elevation: "0" },
                  },
                  [
                    _c(
                      "v-card-title",
                      {
                        staticClass:
                          "d-flex justify-space-between align-center",
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-avatar",
                              { attrs: { size: "36" } },
                              [
                                item.authorPicture
                                  ? _c("v-img", {
                                      attrs: {
                                        "lazy-src": require("@/assets/images/profile-circle.png"),
                                        src: item.authorPicture,
                                      },
                                    })
                                  : _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/images/profile.jpg"),
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "ms-2 me-0 f13" }, [
                              _vm._v(_vm._s(item.authorName)),
                            ]),
                          ],
                          1
                        ),
                        _c("div", [
                          _c("span", { staticClass: "f13" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("moment")(item.createDate, "from", "now")
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c("v-card-text", [
                      _c("p", {
                        staticClass: "f14",
                        domProps: { innerHTML: _vm._s(item.comment) },
                      }),
                    ]),
                    _c("v-divider", { staticClass: "mt-1 mb-0" }),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-content-end" },
                      [
                        _vm.$store.state.mobileNumber == item.userMobile
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "min-height-32 h-auto py-1",
                                attrs: {
                                  flat: "",
                                  depressed: "",
                                  small: "",
                                  color: "red",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteComment(item.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex d-inline-flex align-items-center text-white",
                                  },
                                  [
                                    _c("span", { staticClass: "mx-1 f12" }, [
                                      _vm._v(_vm._s(_vm.$t("Delete"))),
                                    ]),
                                    _c("iconly", {
                                      staticClass: "wh-20",
                                      attrs: { name: "delete", type: "bold" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("v-divider", {
                          staticClass: "mx-1",
                          attrs: { vertical: "" },
                        }),
                        item.liked
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "min-height-32 h-auto py-1",
                                attrs: {
                                  flat: "",
                                  depressed: "",
                                  small: "",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.unLikeComment(item.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex d-inline-flex align-items-center",
                                  },
                                  [
                                    _c("span", { staticClass: "mx-1 f12" }, [
                                      _vm._v(_vm._s(item.likeNumber)),
                                    ]),
                                    _c("iconly", {
                                      staticClass: "wh-20",
                                      attrs: { name: "heart", type: "bold" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _c(
                              "v-btn",
                              {
                                staticClass:
                                  "min-height-32 h-auto py-1 border border-primary",
                                attrs: { flat: "", outlined: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.likeComment(item.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex d-inline-flex align-items-center primary--text",
                                  },
                                  [
                                    _c("span", { staticClass: "mx-1 f12" }, [
                                      _vm._v(_vm._s(item.likeNumber)),
                                    ]),
                                    _c("iconly", {
                                      staticClass: "wh-20",
                                      attrs: { name: "heart", type: "light" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                        _c("v-divider", {
                          staticClass: "mx-1",
                          attrs: { vertical: "" },
                        }),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "500" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "min-height-32 h-auto py-1 border border-accent",
                                              attrs: {
                                                flat: "",
                                                outlined: "",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.reportValue = null
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("iconly", {
                                            staticClass: "wh-20 red--text",
                                            attrs: {
                                              name: "danger",
                                              type: "light",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.reportDialog,
                              callback: function ($$v) {
                                _vm.reportDialog = $$v
                              },
                              expression: "reportDialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "text-center pa-4" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "f16 font-weight-bold d-block",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Report")))]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "f14 p-2 font-weight-light" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "Please write the reason for registration or violation in the box below and register. Your report will be reviewed by our experts"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("v-textarea", {
                                  staticClass: "f14 rounded-lg",
                                  attrs: {
                                    flat: "",
                                    outlined: "",
                                    "auto-grow": "",
                                    counter: "1000",
                                    rules: _vm.reportRules,
                                  },
                                  model: {
                                    value: _vm.reportValue,
                                    callback: function ($$v) {
                                      _vm.reportValue = $$v
                                    },
                                    expression: "reportValue",
                                  },
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { color: "info", block: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.reportValue &&
                                        _vm.reportValue.length > 3
                                          ? _vm.reportContent()
                                          : null
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Submit")))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "v-footer",
            {
              staticClass: "mb-2",
              attrs: {
                fixed: "",
                app: "",
                tile: "",
                color: "transparent",
                padless: "",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "d-flex flew-row justify-space-between pa-2 border",
                      attrs: { elevation: "1" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center pointer text-decoration-none",
                          on: {
                            click: function ($event) {
                              return _vm.openDrBottomSheet(
                                _vm.podcast.publisherId
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-avatar",
                                { attrs: { size: "36" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      "lazy-src": require("@/assets/images/profile-circle.png"),
                                      src: _vm.podcast.publisherPic,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ms-2 me-0 f14" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.podcast.publisherName)),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "text-left d-flex align-items-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "min-height-32 py-1 border border-primary",
                              attrs: { flat: "", outlined: "", small: "" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center primary--text",
                                },
                                [
                                  _c("span", { staticClass: "me-1 ms-0 f14" }, [
                                    _vm._v(_vm._s(_vm.podcast.viewCount)),
                                  ]),
                                  _c("iconly", {
                                    staticClass: "wh-20",
                                    attrs: { type: "bold", name: "show" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("v-spacer", { staticClass: "mx-1" }),
                          _vm.podcast.liked
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "min-height-32 py-1",
                                  attrs: {
                                    flat: "",
                                    depressed: "",
                                    small: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.unLike()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex d-inline-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "me-1 ms-0 f14" },
                                        [_vm._v(_vm._s(_vm.podcast.likeNumber))]
                                      ),
                                      _c("iconly", {
                                        staticClass: "wh-20",
                                        attrs: { name: "heart", type: "bold" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "min-height-32 py-1 border border-primary",
                                  attrs: { flat: "", outlined: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.like()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center primary--text",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "me-1 ms-0 f14" },
                                        [_vm._v(_vm._s(_vm.podcast.likeNumber))]
                                      ),
                                      _c("iconly", {
                                        staticClass: "wh-20",
                                        attrs: { name: "heart", type: "light" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                          _c("v-spacer", { staticClass: "mx-1" }),
                          _vm.podcast.bookmarked
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "min-height-32 py-1",
                                  attrs: {
                                    flat: "",
                                    depressed: "",
                                    small: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteBookmark()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex d-inline-flex align-items-center",
                                    },
                                    [
                                      _c("iconly", {
                                        staticClass: "wh-20",
                                        attrs: {
                                          name: "bookmark",
                                          type: "bold",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "min-height-32 py-1 border border-primary",
                                  attrs: { flat: "", outlined: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.bookmark()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center primary--text",
                                    },
                                    [
                                      _c("iconly", {
                                        staticClass: "wh-20",
                                        attrs: {
                                          name: "bookmark",
                                          type: "light",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("BshDoctor", { ref: "drBottomSheet" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }